import React from "react"
import {GatsbyImage} from "gatsby-plugin-image";
import {getImage} from "gatsby-plugin-image";
import {graphql, Link} from "gatsby";
import Layout from "../../components/Layout";
import {CardBox, CardList, SmallP} from "../../styles/Global.styled";

export default function Releases({data}) {
    const releases = data.allMarkdownRemark.nodes
    console.log('release list')
    console.log(releases)
    return (
        <Layout title="Releases">
            <div>
                <h1>Releases</h1>
            </div>
            <div>
                <CardList>
                    {
                        releases.map((release) => {
                            const tdate = new Date(release.frontmatter.releaseDate)
                            const coverPic = release.frontmatter.cover
                            // const slug = track.frontmatter.slug
                            const slug = release.fields.slug
                            const releaseLink = '/discography' + slug

                            return (
                                <CardBox key={release.id}>
                                    <Link to={releaseLink}>
                                        <div>
                                            <p>
                                                Track: {release.frontmatter.title}
                                            </p>
                                            <SmallP>
                                                Release Date: {tdate.toDateString()}
                                            </SmallP>
                                        </div>
                                        <p>
                                            <GatsbyImage alt="Cover" image={getImage(coverPic)}/>
                                        </p>
                                    </Link>
                                </CardBox>
                            )
                        })
                    }
                </CardList>
            </div>
        </Layout>
    )
}

export const allReleases = graphql`
    query getAllReleases
    {
        allMarkdownRemark(
            filter: {frontmatter: {type: {eq: "release"}}}
            sort: {fields: frontmatter___releaseDate, order: DESC}
        ) {
            nodes {
                fields {
                    slug
                }
                frontmatter {
                    title
                    type
                    releaseDate
                    description
                    cover {
                        childImageSharp {
                            gatsbyImageData(placeholder: TRACED_SVG, width: 150, formats: AUTO)
                        }
                    }
                    spotifyEmbed {
                        code
                    }
                    author
                    genres
                }
                id
                html
            }
        }
    }
`
